import { useIsMarketingHubStatusVisible } from "@circle-react/hooks/useIsMarketingHubStatus";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { AnalyticsLinks } from "./AnalyticsLinks";
import { ContentLinks } from "./ContentLinks";
import { DevelopersLinks } from "./DevelopersLinks";
import { EmailLinks } from "./EmailLinks";
import { MemberLinks } from "./MemberLinks";
import { PaywallLinks } from "./PaywallLinks";
import { PaywallsAffiliatesLinks } from "./PaywallsAffiliatesLinks";
import { PlansLinks } from "./PlansLinks";
import { SettingsLinks } from "./SettingsLinks";
import { SiteLinks } from "./SiteLinks";
import { WorkflowLinks } from "./WorkflowLinks";

export const NestedNavBar = () => {
  const isMarketingHubStatusVisible = useIsMarketingHubStatusVisible();
  const { isV3Enabled } = useIsV3();

  return (
    <div className="bg-primary h-full w-60 p-5 lg:w-[18.5rem]">
      <MemberLinks />
      {isMarketingHubStatusVisible && <EmailLinks />}
      {isV3Enabled && <SiteLinks />}
      <ContentLinks />
      <WorkflowLinks />
      <PaywallLinks />
      <SettingsLinks />
      <PlansLinks />
      <AnalyticsLinks />
      <PaywallsAffiliatesLinks />
      <DevelopersLinks />
    </div>
  );
};
